import { useEffect } from 'react'
import { useDebounce } from '../utils/hooks/useDebounce'
import { useLatestStateReducer } from '../utils/hooks/useLatestStateReducer'
import {
  searchMovies,
  MovieSearchResult, // eslint-disable-line import/named
  MovieSearchResults // eslint-disable-line import/named
} from '../searchMovies'

export enum RESULT_STATUS {
  // main query statuses:
  NO_QUERY = 'NO_QUERY',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NO_RESULTS = 'NO_RESULTS',
  RESULTS_FOUND = 'RESULTS_FOUND',
  // load more statuses:
  LOADING_MORE = 'LOADING_MORE',
  MORE_ERROR = 'MORE_ERROR',
  MORE_RESULTS_FOUND = 'MORE_RESULTS_FOUND',
  ALL_RESULTS_FOUND = 'ALL_RESULTS_FOUND'
}
interface DebouncedMovieSearchOptions {
  query?: string
  debounceDelay: number
}
interface MovieSearchState {
  status: RESULT_STATUS
  result?: MovieSearchResults
}
export interface MovieSearchReturn {
  status: RESULT_STATUS
  results?: MovieSearchResult[]
  loadMore(): void
}

const {
  NO_QUERY,
  LOADING,
  ERROR,
  NO_RESULTS,
  RESULTS_FOUND,
  LOADING_MORE,
  MORE_ERROR,
  MORE_RESULTS_FOUND,
  ALL_RESULTS_FOUND
} = RESULT_STATUS

export function useDebouncedMovieSearch({
  query,
  debounceDelay
}: DebouncedMovieSearchOptions): MovieSearchReturn {
  const debouncedQuery = useDebounce(query, debounceDelay)
  return useMovieSearch(debouncedQuery)
}

export function useMovieSearch(rawQuery: string = ''): MovieSearchReturn {
  const query = trimQuery(rawQuery)
  const initialState: MovieSearchState = { status: NO_QUERY }
  const [{ status, result }, createLatestDispatcher] = useLatestStateReducer(
    initialState
  )

  useEffect((): void => {
    load()
  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    status,
    loadMore,
    results: result && result.results
  }

  async function load(): Promise<void> {
    const dispatch = createLatestDispatcher()
    if (query.length === 0) {
      dispatch({ status: NO_QUERY })
      return
    }
    dispatch({ status: LOADING })
    try {
      const result = await searchMovies({ query })
      dispatch({ status: getResultStatus(result), result })
    } catch (error) {
      dispatch({ status: ERROR })
    }
  }
  async function loadMore(): Promise<void> {
    if (!result || !supportsLoadMore(status)) return
    const dispatch = createLatestDispatcher()
    dispatch({ status: LOADING_MORE, result })
    try {
      const nextResult = await searchMovies({
        query,
        page: result.page + 1
      })
      dispatch({
        status: getNextResultStatus(nextResult),
        result: getUpdatedResult(result, nextResult)
      })
    } catch (error) {
      dispatch({ status: MORE_ERROR, result })
    }
  }
}

function trimQuery(rawQuery: string): string {
  return rawQuery.trim().replace(/\s{2,}/g, ' ')
}
function supportsLoadMore(status: RESULT_STATUS): boolean {
  return [RESULTS_FOUND, MORE_ERROR, MORE_RESULTS_FOUND].includes(status)
}
function getResultStatus({
  results,
  page,
  total_pages
}: MovieSearchResults): RESULT_STATUS {
  if (results.length === 0) return NO_RESULTS
  if (page === total_pages) return ALL_RESULTS_FOUND
  else return RESULTS_FOUND
}
function getNextResultStatus({
  page,
  total_pages
}: MovieSearchResults): RESULT_STATUS {
  if (page === total_pages) return ALL_RESULTS_FOUND
  else return MORE_RESULTS_FOUND
}
function getUpdatedResult(
  current: MovieSearchResults,
  next: MovieSearchResults
): MovieSearchResults {
  return {
    page: next.page,
    total_pages: next.total_pages,
    results: current.results.concat(next.results)
  }
}
