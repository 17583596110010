import styled from '../../theme/themed-styled-components'

export interface Props {
  minItemWidth: string
}
export default styled.ul<Props>`
  display: grid;
  grid-gap: 0.2em;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ minItemWidth }): string => minItemWidth}, 1fr)
  );
  justify-content: center;
  margin: 0.2em;
  padding: 0;
  list-style: none;
`
