import React from 'react'
import List, { Props as ListProps } from './List'

interface Props extends ListProps {
  children: React.ReactChild[]
}
export default function Grid({
  children,
  minItemWidth,
  ...rest
}: Props): React.ReactElement {
  return (
    <List {...rest} minItemWidth={minItemWidth}>
      {React.Children.map(children, wrapInLi)}
    </List>
  )
}

function wrapInLi(child: React.ReactChild): React.ReactElement {
  return <li>{child}</li>
}
