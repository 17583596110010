import * as pol from 'polished'
import styled from '../../theme/themed-styled-components'
import Sticky from '../Box/Sticky'

export default styled(Sticky)`
  z-index: 2;
  border-top: 1px solid ${({ theme }): string => theme.HEADER.HIGHLIGHT};
  border-bottom: 1px solid ${({ theme }): string => theme.HEADER.HIGHLIGHT};
  background-color: ${({ theme }): string => theme.HEADER.BACKGROUND};
  box-shadow: 0 3px 5px
    ${({ theme }): string => pol.rgba(theme.BODY.BACKGROUND, 0.5)};
`
