import * as pol from 'polished'
import styled from '../../theme/themed-styled-components'

export default styled.input`
  outline: none;
  border-radius: 0;
  border: 1px solid ${({ theme }): string => pol.rgba(theme.BRAND, 0)};
  padding: 0.9rem 1.8rem;
  width: 100%;
  height: 3.24rem;
  font-size: 1.2rem;
  color: ${({ theme }): string => theme.HEADER.TEXT};
  background-color: ${({ theme }): string =>
    pol.rgba(theme.HEADER.HIGHLIGHT, 0)};
  transition: 100ms ease-in-out;

  &::placeholder {
    color: ${({ theme }): string => theme.HEADER.TEXT};
  }

  &:hover {
    border-color: ${({ theme }): string => pol.rgba(theme.BRAND, 0.5)};
    background-color: ${({ theme }): string =>
      pol.rgba(theme.HEADER.HIGHLIGHT, 0.2)};
  }

  &:focus {
    border-color: ${({ theme }): string => pol.rgba(theme.BRAND, 1)};
    background-color: ${({ theme }): string =>
      pol.rgba(theme.HEADER.HIGHLIGHT, 0.1)};
  }
`
