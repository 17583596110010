import React from 'react'
import FillFlexVertical from '../Box/FillFlexVertical'
import Header from './Header'

interface Props {
  header: React.ReactElement
  body: React.ReactElement
}
export default function Card({
  header,
  body,
  ...rest
}: Props): React.ReactElement {
  return (
    <FillFlexVertical {...rest}>
      <Header as="header">{header}</Header>
      <FillFlexVertical>{body}</FillFlexVertical>
    </FillFlexVertical>
  )
}
