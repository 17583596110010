import * as pol from 'polished'
import styled from '../../theme/themed-styled-components'

export default styled.p`
  overflow: hidden;
  flex: 1;
  -webkit-text-fill-color: transparent;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-background-clip: text;
  background-image: linear-gradient(
    ${({ theme }): string => pol.rgba(theme.BODY.TEXT, 1)} 50%,
    ${({ theme }): string => pol.rgba(theme.BODY.TEXT, 0)}
  );
`
