export type URLParams = Record<string, boolean | number | string>
interface URLOptions {
  origin: string
  path?: string
  params?: URLParams
}

export function createURL({ origin, path = '', params = {} }: URLOptions): URL {
  const url = new URL(pathJoin(origin, path))
  for (const [param, value] of Object.entries(params))
    url.searchParams.append(param, value.toString())
  return url
}

export function pathJoin(...paths: string[]): string {
  return paths.map(trimSlashes).join('/')
}

function trimSlashes(path: string, index: number, paths: string[]): string {
  let trimmed = path
  if (index !== paths.length - 1) {
    trimmed = trimmed.replace(/\/+$/, '')
  }
  if (index !== 0) {
    trimmed = trimmed.replace(/^\/+/, '')
  }
  return trimmed
}
