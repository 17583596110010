import * as pol from 'polished'
import styled from '../../theme/themed-styled-components'

export default styled.button`
  border: 1px solid ${({ theme }): string => pol.rgba(theme.BRAND, 0)};
  border-left-color: ${({ theme }): string => theme.HEADER.HIGHLIGHT};
  padding: 0.75em 1.5em;
  color: ${({ theme }): string => theme.HEADER.TEXT};
  background-color: ${({ theme }): string => pol.rgba(theme.BRAND, 0)};

  transition: 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }): string => pol.rgba(theme.BRAND, 0.5)};
    background-color: ${({ theme }): string => pol.rgba(theme.BRAND, 0.2)};
  }

  &:active {
    transform: scale(0.97);
  }
`
