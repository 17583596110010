import React from 'react'
import Card from '../../../components/Card/Card'
import Sticky from '../../../components/Box/Sticky'
import Title from '../../../components/Text/Title'
import Padding from '../../../components/Box/Padding'
import FillImage from '../../../components/Image/FillImage'
import SquareButton from '../../../components/Button/SquareButton'
import FillFlexSpread from '../../../components/Box/FillFlexSpread'

import { MovieSearchResult } from '../../../modules/tmdb-api/searchMovies'

interface Props {
  movie: MovieSearchResult
  onDeselect(): void
}
export default function Movie({
  movie,
  onDeselect
}: Props): React.ReactElement {
  const {
    posters,
    original_title,
    overview,
    vote_average,
    vote_count,
    popularity
  } = movie
  return (
    <Card
      data-testid="movie"
      header={
        <FillFlexSpread>
          <Title>{original_title}</Title>
          <SquareButton onClick={onDeselect}>Deselect</SquareButton>
        </FillFlexSpread>
      }
      body={
        <Sticky top={1.24 * 2.7 + 'em'}>
          <FillImage src={posters.w500} alt="" maxHeight={200} />
          <Padding>
            <h2>{original_title}</h2>
            <p>{overview}</p>
            <dl>
              <dt>Voter Score</dt>
              <dd>
                {vote_average}/10 ({vote_count} votes)
              </dd>
              <dt>Popularity</dt>
              <dd>{popularity}</dd>
            </dl>
          </Padding>
        </Sticky>
      }
    />
  )
}
