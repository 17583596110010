import fetchFromTheMovieDatabase from './fetchFromTheMovieDatabase'

export interface MovieSearchOptions {
  query: string
  page?: number
}

export interface RawMovieSearchResults {
  page: number
  total_pages: number
  results: RawMovieSearchResult[]
}

export interface RawMovieSearchResult {
  id: number
  overview: string
  original_title: string
  poster_path?: string
  backdrop_path?: string
  vote_count: number
  vote_average: number
  popularity: number
}

export default function fetchMovieSearchResults({
  query,
  page = 1
}: MovieSearchOptions): Promise<RawMovieSearchResults> {
  return fetchFromTheMovieDatabase<RawMovieSearchResults>({
    path: '/search/movie',
    params: { query, page }
  })
}
