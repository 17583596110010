import { fetchJsonp } from '../utils/jsonp'
import { createURL, URLParams } from '../utils/url'

declare var process: {
  env: {
    TMDB_API_ORIGIN: string
    TMDB_API_KEY: string
  }
}

interface URLOptions {
  path: string
  params?: URLParams
}

export default function fetchFromTheMovieDatabase<T>({
  path,
  params
}: URLOptions): Promise<T> {
  const url = createURL({
    origin: process.env.TMDB_API_ORIGIN,
    path,
    params: {
      api_key: process.env.TMDB_API_KEY,
      ...params
    }
  })
  return fetchJsonp<T>(url.toString())
}
