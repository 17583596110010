import { useReducer } from 'react'

interface State<T> {
  id: number
  latestState: T
}
type Dispatcher<T> = (latestState: T) => void
type DispatcherFactory<T> = () => Dispatcher<T>
type Reducer<T> = (state: State<T>, stateUpdate: State<T>) => State<T>
export type LatestStateReturn<T> = [T, DispatcherFactory<T>]

let latestDispatchID = 0
export function useLatestStateReducer<T>(
  initialState: T
): LatestStateReturn<T> {
  const [{ latestState }, dispatch] = useReducer(
    latestStateReducer as Reducer<T>,
    {
      id: latestDispatchID,
      latestState: initialState
    }
  )

  return [latestState, createLatestDispatcher]

  function createLatestDispatcher(): Dispatcher<T> {
    const id = latestDispatchID++
    return (latestState): void => dispatch({ id, latestState })
  }
}

function latestStateReducer<T>(
  state: State<T>,
  stateUpdate: State<T>
): State<T> {
  return stateUpdate.id < state.id ? state : stateUpdate
}
