// eslint-disable-next-line import/no-duplicates
import * as styledComponents from 'styled-components'
// eslint-disable-next-line import/no-duplicates,import/named
import { ThemedStyledComponentsModule } from 'styled-components'
import theme from './theme'

const {
  default: styled,
  css,
  createGlobalStyle,
  ThemeProvider,
  ThemeConsumer,
  keyframes
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = (styledComponents as any) as ThemedStyledComponentsModule<typeof theme>

export { css, createGlobalStyle, keyframes, ThemeProvider, ThemeConsumer }
export default styled
