import { useState } from 'react'

export interface SelectionReturn<S, D = null> {
  selection: S | D | null
  select(selection: S): void
  deselect(): void
}
export function useSelection<S, D = null>(
  initialValue: S | null = null,
  deselectionValue: D | null = null
): SelectionReturn<S, D> {
  const [selection, setSelection] = useState<S | D | null>(initialValue)
  return {
    selection,
    select: setSelection,
    deselect(): void {
      setSelection(deselectionValue)
    }
  }
}
