import React from 'react'
import MoviePicker from './MoviePicker/MoviePicker'
import Comparison from '../components/Comparison/Comparison'
import { SelectionReturn } from '../utils/hooks/useSelection'
import { MovieSearchResult } from '../modules/tmdb-api/searchMovies'

const firstMoviePicker = getMoviePickerWithFallback(
  'Choose your first movie in the search box above'
)
const secondMoviePicker = getMoviePickerWithFallback(
  'Now, choose one more movie...'
)

export default function Main(): React.ReactElement {
  return (
    <Comparison<MovieSearchResult>
      one={firstMoviePicker}
      two={secondMoviePicker}
    />
  )
}

function getMoviePickerWithFallback(
  fallback: string
): (props: SelectionReturn<MovieSearchResult>) => React.ReactElement {
  return function MoviePickerWithFallback({
    select,
    deselect,
    selection
  }: SelectionReturn<MovieSearchResult>): React.ReactElement {
    return (
      <MoviePicker
        onSelect={select}
        onDeselect={deselect}
        selection={selection}
        fallback={fallback}
      />
    )
  }
}
