// eslint-disable-next-line import/named
import { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from '../../theme/themed-styled-components'

interface Props {
  maxHeight?: number
}
export default styled.img<Props>`
  width: 100%;
  height: auto;
  object-fit: cover;
  vertical-align: middle;
  ${getMaxHeight}
`

function getMaxHeight({
  maxHeight
}: Props): false | FlattenSimpleInterpolation {
  return (
    typeof maxHeight === 'number' &&
    css`
      max-height: ${maxHeight}px;
    `
  )
}
