import React from 'react'
import Input from '../../../../components/Form/Input'
import FormContainer from '../../../../components/Form/Form'

interface Props {
  title: string
  query: string
  onSearch(event: React.ChangeEvent<HTMLInputElement>): void
}
export default function Form({
  title,
  query,
  onSearch
}: Props): React.ReactElement {
  return (
    <FormContainer onSubmit={preventDefault}>
      <Input
        title={title}
        aria-label={title}
        placeholder={title}
        value={query}
        onChange={onSearch}
        type="search"
        maxLength={2048}
        autoCorrect="off"
        autoComplete="off"
        autoCapitalize="off"
        spellCheck={false}
        aria-haspopup="false"
        aria-autocomplete="both"
      />
    </FormContainer>
  )
}

function preventDefault(event: React.FormEvent): void {
  event.preventDefault()
}
