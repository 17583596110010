import * as pol from 'polished'
import styled, { css } from '../../../theme/themed-styled-components'

const activeContainerStyles = css`
  z-index: 1;
  border-color: ${({ theme }): string => pol.rgba(theme.BRAND, 0.5)};
  box-shadow: 0.25em 0.25em 0.75em
    ${({ theme }): string => pol.darken(0.05, theme.BODY.BACKGROUND)};
  transform: scale(1.1);
  transition-duration: 150ms;
`

interface Props {
  active: boolean
}
export default styled.div<Props>`
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border: 1px solid ${({ theme }): string => pol.rgba(theme.BRAND, 0)};
  height: 100%;
  background-color: ${({ theme }): string =>
    pol.darken(0.011, theme.BODY.BACKGROUND)};
  box-shadow: 0 0 0.3em
    ${({ theme }): string => pol.darken(0.05, theme.BODY.BACKGROUND)};
  transition: 400ms ease-in-out;

  &:hover {
    transition-duration: 150ms;
    border-color: ${({ theme }): string => pol.rgba(theme.BRAND, 1)};
  }

  ${({ active }): false | typeof activeContainerStyles =>
    active && activeContainerStyles}
`
