import React from 'react'
import Result from './Result'
import ViewEnterObserver from '../../../../modules/view-observer/ViewEnterObserver'
import { useMultiToggle } from '../../../../utils/hooks/useMultiToggle'
import { MovieSearchResult } from '../../../../modules/tmdb-api/searchMovies'

import Grid from '../../../../components/Grid/Grid'
import Margin from '../../../../components/Box/Margin'

interface Props {
  results: MovieSearchResult[]
  onSelect(result: MovieSearchResult): void
  onScrolledToBottom(): void
}
export default function Results({
  results,
  onSelect,
  onScrolledToBottom
}: Props): React.ReactElement {
  const [active, toggleActive] = useMultiToggle<MovieSearchResult>()
  return (
    <>
      <Margin data-testid="results:message">
        Tap on a movie for more details:
      </Margin>
      <Grid minItemWidth="200px" data-testid="results">
        {results.map(
          (result): React.ReactElement => (
            <Result
              {...result}
              key={result.id}
              isActive={active === result}
              onSelect={(): void => onSelect(result)}
              onClick={(): void => toggleActive(result)}
            />
          )
        )}
      </Grid>
      <ViewEnterObserver
        data-testid="view-enter-observer"
        onEnterView={onScrolledToBottom}
      />
    </>
  )
}
