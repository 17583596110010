import React from 'react'
import Container from './Container'
import Overlay from './Overlay'

interface Props {
  active: boolean
  overlay: React.ReactChild
  background: React.ReactChild
  onClick(event: React.KeyboardEvent | React.MouseEvent): void
}
export default function Item({
  active,
  overlay,
  background,
  onClick,
  ...rest
}: Props): React.ReactElement {
  function handleSpaceKeyPress(event: React.KeyboardEvent): void {
    if (event.key !== ' ') return
    event.preventDefault()
    onClick(event)
  }

  return (
    <Container
      tabIndex={0}
      active={active}
      onClick={onClick}
      onKeyPress={handleSpaceKeyPress}
      {...rest}
    >
      {background}
      <Overlay active={active}>{overlay}</Overlay>
    </Container>
  )
}
