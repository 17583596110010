import React, { useEffect } from 'react'
import ReactGA from './modules/analytics'
import { ThemeProvider } from 'styled-components'
import theme from './theme/theme'

import Main from './screens/Main'

export default function App(): React.ReactElement {
  useEffect((): void => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Main />
    </ThemeProvider>
  )
}
