import fetchFromTheMovieDatabase from './fetchFromTheMovieDatabase'

export interface RawConfig {
  images: {
    secure_base_url: string
    backdrop_sizes: string[]
    poster_sizes: string[]
  }
}

export default function fetchConfig(): Promise<RawConfig> {
  return fetchFromTheMovieDatabase<RawConfig>({ path: '/configuration' })
}
