import React, { useRef, useLayoutEffect } from 'react'

type Effect = () => void

export type Props = JSX.IntrinsicElements['div'] & {
  onEnterView: Effect
}
export default function ViewEnterObserver({
  onEnterView,
  ...rest
}: Props): React.ReactElement {
  return (
    <div {...rest} ref={useViewEnterObserver<HTMLDivElement>(onEnterView)} />
  )
}

function useViewEnterObserver<T extends Element>(
  onEnterView: Effect
): React.RefObject<T> {
  const ref = useRef<T>(null)
  useLayoutEffect((): Effect | void => {
    const observableElement = ref.current
    // istanbul ignore next
    if (!observableElement) return
    const observer = new IntersectionObserver(
      ([{ intersectionRatio }]): void => {
        if (intersectionRatio <= 0) return
        onEnterView()
      }
    )
    observer.observe(observableElement)
    return (): void => observer.unobserve(observableElement)
  }, [onEnterView])
  return ref
}
