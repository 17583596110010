import { useState } from 'react'

export type MultiToggleReturn<T> = [T | null, (newValue: T) => void]
export function useMultiToggle<T>(
  initialValue: T | null = null
): MultiToggleReturn<T> {
  const [value, setValue] = useState(initialValue)
  return [value, toggle]

  function toggle(newValue: T): void {
    setValue(newValue === value ? null : newValue)
  }
}
