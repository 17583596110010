export default {
  BRAND: 'tomato',
  HEADER: {
    BACKGROUND: '#21191d',
    HIGHLIGHT: '#333',
    TEXT: '#eee'
  },
  BODY: {
    BACKGROUND: '#2a2025',
    HIGHLIGHT: '#555',
    TEXT: '#eee'
  }
}
