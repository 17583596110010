import * as pol from 'polished'
import styled, { css } from '../../../theme/themed-styled-components'

const activeOverlayStyles = css`
  transition-timing-function: ease-out;
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
`

interface Props {
  active: boolean
}
export default styled.div<Props>`
  position: absolute;
  top: 20%;
  left: 0;

  display: flex;
  flex-direction: column;

  border-top: 1px solid
    ${({ theme }): string =>
      pol.rgba(pol.mix(0.5, theme.BODY.BACKGROUND, theme.BRAND), 0.25)};
  padding: 1em;
  width: 100%;
  height: 80%;
  background-color: ${({ theme }): string =>
    pol.rgba(theme.BODY.BACKGROUND, 0.8)};
  box-shadow: 0 0 1em ${({ theme }): string => theme.BODY.BACKGROUND};
  opacity: 0;
  transform: translateY(15%);
  transition: 200ms ease-in;
  visibility: hidden;

  ${({ active }): false | typeof activeOverlayStyles =>
    active && activeOverlayStyles}
`
