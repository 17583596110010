import React, { useState } from 'react'

export type InputReturn = [
  string,
  (e: React.ChangeEvent<HTMLInputElement>) => void
]
export function useInput(initialValue = ''): InputReturn {
  const [value, setValue] = useState(initialValue)
  return [value, (event): void => setValue(event.target.value)]
}
