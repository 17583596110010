import React from 'react'
import BigInfo from '../../../../components/Text/BigInfo'
import FillFlexCenter from '../../../../components/Box/FillFlexCenter'
import { RESULT_STATUS } from '../../../../modules/tmdb-api/hooks/useMovieSearch'

interface Props {
  status: RESULT_STATUS
  defaultFallback: string
}
export default function Fallback({
  status,
  defaultFallback
}: Props): React.ReactElement {
  return (
    <FillFlexCenter>
      <BigInfo maxWidth="500px" data-testid="results:message">
        {getFallbackMessage(status, defaultFallback)}
      </BigInfo>
    </FillFlexCenter>
  )
}

function getFallbackMessage(
  status: RESULT_STATUS,
  defaultFallback: string
): string {
  switch (status) {
    case RESULT_STATUS.LOADING:
      return 'Loading...'
    case RESULT_STATUS.ERROR:
      return 'Oops! Something appears to have gone wrong on our end. Please, try again!'
    case RESULT_STATUS.NO_RESULTS:
      return 'Sorry, no movies in our database match that query'
    default:
      return defaultFallback
  }
}
