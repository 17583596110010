import * as ReactGA from 'react-ga'

declare var process: {
  env: {
    GA_TRACKING_ID: string
  }
}

ReactGA.initialize(process.env.GA_TRACKING_ID)

export default ReactGA
