import React from 'react'
import Form from './Form/Form'
import Results from './Results/Results'
import Fallback from './Results/Fallback'

import Card from '../../../components/Card/Card'
import FillFlexCenter from '../../../components/Box/FillFlexCenter'

import { MovieSearchReturn } from '../../../modules/tmdb-api/hooks/useMovieSearch'
import { MovieSearchResult } from '../../../modules/tmdb-api/searchMovies'

interface Props {
  query: string
  fallback: string
  result: MovieSearchReturn
  onSearch(event: React.ChangeEvent): void
  onSelect(result: MovieSearchResult): void
}
export default function MovieSearch({
  query,
  result,
  fallback,
  onSearch,
  onSelect
}: Props): React.ReactElement {
  const { status, results, loadMore } = result
  return (
    <Card
      header={
        <FillFlexCenter>
          <Form
            query={query}
            onSearch={onSearch}
            title="Search for a movie..."
          />
        </FillFlexCenter>
      }
      body={
        hasResults(results) ? (
          <Results
            results={results}
            onSelect={onSelect}
            onScrolledToBottom={loadMore}
          />
        ) : (
          <Fallback status={status} defaultFallback={fallback} />
        )
      }
    />
  )
}

function hasResults(
  results?: MovieSearchResult[]
): results is MovieSearchResult[] {
  return results !== undefined && results.length > 0
}
