import styled from '../../theme/themed-styled-components'

interface Props {
  maxWidth: string
}
export default styled.span<Props>`
  max-width: ${({ maxWidth }): string => maxWidth};
  font-size: 2em;
  text-align: center;
`
