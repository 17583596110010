import React from 'react'
import { useInput } from '../../utils/hooks/useInput'
import { useDebouncedMovieSearch } from '../../modules/tmdb-api/hooks/useMovieSearch'

import Movie from './Movie/Movie'
import MovieSearch from './MovieSearch/MovieSearch'
import { MovieSearchResult } from '../../modules/tmdb-api/searchMovies'

interface Props {
  fallback: string
  selection: MovieSearchResult | null
  onSelect(result: MovieSearchResult): void
  onDeselect(): void
}
export default function MoviePicker({
  selection,
  onSelect,
  onDeselect,
  fallback
}: Props): React.ReactElement {
  const [query, setQuery] = useInput()
  const result = useDebouncedMovieSearch({ query, debounceDelay: 500 })
  return selection ? (
    <Movie movie={selection} onDeselect={onDeselect} />
  ) : (
    <MovieSearch
      query={query}
      result={result}
      onSearch={setQuery}
      onSelect={onSelect}
      fallback={fallback}
    />
  )
}
