import { useState, useEffect } from 'react'

export function useDebounce<T>(value: T, delay: number): T | undefined {
  const [debouncedValue, setDebouncedValue] = useState<T>()
  useEffect((): (() => void) => {
    const handler = setTimeout((): void => setDebouncedValue(value), delay)
    return (): void => clearTimeout(handler)
  }, [value, delay])
  return debouncedValue
}
