import originalFetchJsonp from 'fetch-jsonp'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestCache: Record<string, any> = {}
export async function fetchJsonp<T>(url: string): Promise<T> {
  if (requestCache[url]) return requestCache[url]
  const response = await originalFetchJsonp(url)
  requestCache[url] = await response.json()
  return requestCache[url]
}
