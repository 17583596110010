import React from 'react'
import Item from './Item'
import FillFlex from '../Box/FillFlex'
import { useSelection, SelectionReturn } from '../../utils/hooks/useSelection'

interface Props<T, U> {
  one(props: SelectionReturn<T>): React.ReactElement
  two(props: SelectionReturn<U>): React.ReactElement
}
export default function Comparison<T, U = T>({
  one,
  two
}: Props<T, U>): React.ReactElement {
  const first = useSelection<T>()
  const second = useSelection<U>()

  return (
    <FillFlex>
      <Item>{one(first)}</Item>
      {Boolean(first.selection || second.selection) && (
        <Item>{two(second)}</Item>
      )}
    </FillFlex>
  )
}
