import React from 'react'
import { MovieSearchResult } from '../../../../modules/tmdb-api/searchMovies'

import Item from '../../../../components/Grid/Item/Item'
import FillImage from '../../../../components/Image/FillImage'
import SubTitle from '../../../../components/Text/SubTitle'
import ShortDescription from '../../../../components/Text/ShortDescription'
import Button from '../../../../components/Button/Button'

type Props = MovieSearchResult & {
  isActive: boolean
  onSelect(event: React.MouseEvent): void
  onClick(event: React.KeyboardEvent | React.MouseEvent): void
}
export default function Result({
  id,
  original_title,
  overview,
  posters,
  isActive,
  onSelect,
  onClick
}: Props): React.ReactElement {
  return (
    <Item
      active={isActive}
      onClick={onClick}
      data-testid={`result:${id}`}
      background={
        <FillImage
          width={500}
          height={750}
          src={posters.w500}
          alt={original_title}
        />
      }
      overlay={
        <>
          <SubTitle>{original_title}</SubTitle>
          <ShortDescription>{overview}</ShortDescription>
          <Button onClick={onSelect}>Select</Button>
        </>
      }
    />
  )
}
