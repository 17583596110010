import { Overwrite } from 'type-zoo/types'
import { pathJoin } from './utils/url'
import fetchConfig from './core/fetchConfig'
import fetchMovieSearchResults, {
  MovieSearchOptions,
  RawMovieSearchResult,
  RawMovieSearchResults
} from './core/fetchMovieSearchResults'

export type ImagePaths = Record<string, string>
export type MovieSearchResult = RawMovieSearchResult & {
  posters: ImagePaths
  backdrops: ImagePaths
}
export type MovieSearchResults = Overwrite<
  RawMovieSearchResults,
  {
    results: MovieSearchResult[]
  }
>

export async function searchMovies({
  query,
  page
}: MovieSearchOptions): Promise<MovieSearchResults> {
  const [config, search] = await Promise.all([
    fetchConfig(),
    fetchMovieSearchResults({ query, page })
  ])

  const { secure_base_url, backdrop_sizes, poster_sizes } = config.images
  const results = search.results.map(
    (result): MovieSearchResult => ({
      ...result,
      posters: getImagePaths(poster_sizes, result.poster_path),
      backdrops: getImagePaths(backdrop_sizes, result.backdrop_path)
    })
  )

  return {
    ...search,
    results
  }

  function getImagePaths(sizes: string[], image?: string): ImagePaths {
    return typeof image !== 'string'
      ? {}
      : sizes.reduce(
          (sizes: ImagePaths, size): ImagePaths => ({
            ...sizes,
            [size]: pathJoin(secure_base_url, size, image)
          }),
          {}
        )
  }
}
