import * as pol from 'polished'
import styled from '../../theme/themed-styled-components'

export default styled.button`
  cursor: pointer;
  border: 1px solid ${({ theme }): string => pol.rgba(theme.BODY.TEXT, 0)};
  border-radius: 3px;
  padding: 0.5em 1em;
  color: ${({ theme }): string => theme.BODY.TEXT};
  text-shadow: 0 0 ${({ theme }): string => theme.BODY.BACKGROUND};
  background-color: ${({ theme }): string => theme.BRAND};
  box-shadow: 0 0 0 ${({ theme }): string => theme.BODY.BACKGROUND};
  transition: 100ms;

  &:hover {
    border-color: ${({ theme }): string => pol.rgba(theme.BODY.TEXT, 0.5)};
    text-shadow: 0 0 0.1em ${({ theme }): string => theme.BODY.BACKGROUND};
    box-shadow: 0 0 0.5em ${({ theme }): string => theme.BODY.BACKGROUND};
    background-color: ${({ theme }): string => pol.lighten(0.012, theme.BRAND)};
  }

  &:active {
    transform: scale(0.97);
  }
`
